<template>
  <div>
    <!-- //第一个卡片 -->
    <el-card v-if="digital == 1">
      <!-- <el-card body-style="padding:8px;"> -->
      <el-form :inline="true">
        <el-form-item label="项目名称:">
          <el-input placeholder="请输入项目名称" size="small" v-model="project"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" style="margin-left: 10px" @click="query">查询</el-button>
          <el-button type="warning" size="small" @click="reset">重置</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="success" size="mini" v-print="printObj1">打印</el-button>
          <!-- <el-button type="success" size="mini" v-print="printObj1" >打印全部</el-button> -->
        </el-form-item>
      </el-form>
      <!-- </el-card> -->
      <!-- 表单 -->
      <div >
        <el-table :data="tableData" stripe style="width: 100%; border-radius: 5px; padding-top: 5px"
          :header-cell-style="{ background: '#0000CD', color: '#ffffff' }" id="printTests">
          <!-- <el-table-column type="selection" width="55">
          </el-table-column> -->
          <el-table-column prop="name" label="项目名称"></el-table-column>
          <el-table-column prop="corporations" label="所属公司"></el-table-column>
          <el-table-column prop="site" label="所属区域"></el-table-column>
          <el-table-column prop="hedden_num" label="排查次数"></el-table-column>
          <el-table-column prop="hedden_time" label="最后排查时间"></el-table-column>
          <el-table-column prop="address" label="操作">
            <template slot-scope="scope">
              <el-link type="primary" :underline="false" @click="idchaxun(scope.row.id)">查看详情</el-link>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- <div id="printTests" v-show="false">
        <el-table :data="tableData1" stripe style="width: 100%; border-radius: 5px; padding-top: 5px"
          :header-cell-style="{ background: '#0000CD', color: '#ffffff' }" >
          <el-table-column prop="name" label="项目名称"></el-table-column>
          <el-table-column prop="corporations" label="所属公司"></el-table-column>
          <el-table-column prop="site" label="所属区域"></el-table-column>
          <el-table-column prop="hedden_num" label="排查次数"></el-table-column>
          <el-table-column prop="hedden_time" label="最后排查时间"></el-table-column>
        </el-table>
      </div> -->
      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
        :page-sizes="[5, 10, 20]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </el-card>
    <!-- 第二个卡片 -->
    <el-card v-if="digital == 2">
      <el-button type="primary" round @click="superior" class="butt" size="mini">返回上一级</el-button>
      <el-card>
        <el-form :inline="true">
          <el-form-item label="排查时间:">
            <el-date-picker v-model="start_time" type="date" placeholder="选择日期" size="small">
            </el-date-picker>
            ~
            <el-date-picker v-model="end_time" type="date" placeholder="选择日期" size="small">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="排查类型:">
            <el-select v-model="type" placeholder="全部" size="small">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-button type="primary" size="small" style="margin:5px 10px;" @click="query2">查询</el-button>
          <el-button type="warning" size="small" @click="reset2">重置</el-button>
        </el-form>

        <!-- 表单 -->
        <el-table :data="table2" stripe style="width: 100%; border-radius: 5px; padding-top: 5px"
          :header-cell-style="{ background: '#0000CD', color: '#ffffff' }" v-infinite-scroll="load">
          <el-table-column prop="id" label="编号"></el-table-column>
          <el-table-column prop="pro_name" label="	工程名称"></el-table-column>
          <el-table-column prop="work_type" label="排查类型"></el-table-column>
          <el-table-column prop="" label="排查部位/场所">施工现场区</el-table-column>
          <el-table-column prop="create_times" label="检查时间"></el-table-column>
          <el-table-column prop="user_name" label="检查负责人"></el-table-column>
          <el-table-column prop="on_handling" label="检查处理情况">
            <template slot-scope="scope">
              <div v-if="scope.row.on_handling == '无隐患'">无隐患</div>
              <div v-else>{{ scope.row.datas.data3.name }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="chakan(scope.row.id, scope.row.datas.data3.name)">查看</el-button>
              <el-button type="text" @click="del_shanchu(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <!-- <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[5,10,20]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total1"
      >
      </el-pagination> -->
        <el-dialog title="隐患排查记录表" :visible.sync="dialogVisible" width="49%">

          <el-button type="text" v-print="'#data1'">打印隐患排查记录表</el-button>
          <el-button type="text" v-print="'#data2'" v-if="wentichuli == '下发通知'">打印隐患整改通知单</el-button>

          <table border="1" id='data1' style="border-collapse: collapse;">
            <tr>
              <td style="width: 140px;height: 70px;">
                施工单位
              </td>
              <td colspan="3" style="width: 680px;height: 70px;">
                {{ data_wenjian.pro_name }}
              </td>
              <td rowspan="2" style="width: 140px;height: 70px;">
                检查类型
              </td>
              <td rowspan="2" style="width: 240px;height: 70px;">
                {{ data_wenjian.work_type }}
              </td>
            </tr>
            <tr>
              <td style="width: 140px;height: 70px;">
                检查时间
              </td>
              <td style="width: 200px;height: 70px;">
                {{ data_wenjian.create_times }}
              </td>
              <td style="width: 80px;height: 70px;">
                检查负责人
              </td>
              <td>
                {{ data_wenjian.user_name }}
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <p>检查内容： 共 {{ count_all }}项排查清单</p>
                <br>
                <p v-for="(item, i) in list_data" :key="i">{{ i }} -{{ item }}</p>
                <!-- <p>1、作业活动类：桩基工程-CFG桩-准备工作-钻机定位，未对钻机进行安 全检查，钻机安装不牢固</p>
                <p>2、作业活动类：桩基工程-CFG桩-准备工作-钻进作业，作业过程中无关人员随意进入施 工现场；操作人员擅自离场</p>
                <p>3、作业活动类：桩基工程-CFG桩成桩-桩头处理，桩头环切过程中人员操作不当</p>
                <p>4、作业活动类：钢筋工程-钢筋安装-安装梁、板钢筋，钢筋吊装到位后未设置临时固定措施</p>
                <p>5、作业活动类：基坑工程-基坑开挖-机械挖土，未按设计和施工方案的要求分层、分段开完或开挖不均衡</p>
                <p>6、作业活动类：基坑工程-基坑开挖-机械挖土，基坑支护结构混凝土强度未达到设计要求，提前开挖和超挖</p>
                <p>7、作业活动类：基坑工程-基坑开挖-机械挖土，基坑开挖下层土前，未对围护结构进行检测或检测不合格</p>
                <p>8、作业活动类：基坑工程-基坑开挖-机械挖土，开挖过程中未采取设备、重物支撑、腰梁、锚杆等防碰撞措施</p>
                <p>9、作业活动类：基坑工程-基坑开挖-机械挖土，基坑内施工机械未保证安全有效距离</p>
                <p>10、作业活动类：基坑工程-基坑开挖-人工修整，基坑内开挖深度超过2m未设置供施工人员上下的专用梯道或梯道</p>
                <p>11、作业活动类：基坑工程-基坑开挖-人工修整，人工在基坑内修整时，未采取垂直防护措施</p>
                <p>12、作业活动类：基坑工程-基坑监测-监测项目，未按设计及方案要求对基坑工程进行监测</p>
                <p>13、作业活动类：基坑工程-基坑监测-监测频率，监测的时间间隔不符合方案要求或监测结果变化速率较大未加密观测次数</p>
                <p>14、作业活动类：基坑工程-基坑监测-检测值报警，监测报警值未根据土质特性、设计结果及当地经验等因素确定</p>
                <p>15、作业活动类：模板工程-模板加工-材料堆放，模板加工区未配置消防器材。</p>
                <p>16、作业活动类：模板工程-模板加工-材料堆放，模板加工区未按平面布置要求与作业区、非施工区做有效隔离。</p>
                <p>17、作业活动类：模板工程-模板加工-模板、木方下料，加工区未设置防护棚或防护棚设置不符合要求。</p>
                <p>18、作业活动类：模板工程-模板加工-模板、木方下料，作业人员戴手套操作加工机械时。</p>
                <p>19、作业活动类：模板工程-模板加工-模板、木方下料，加工区杂物及废料未做到“日产日清”。</p>
                <p>20、作业活动类：模板工程-模板加工-模板、木方下料，加工区机械在作业完毕后未切断电源。</p> -->
                <br>
                <br>
                <br>
                <p>检查结果：发现 {{ count_on }} 条隐患:</p>
                <br>
                <br>
                <div v-if="count_on != 0">
                  <p v-for="(item) in list_data_on" :key='item.text' style="padding-left: 60px;">
                    {{ item.text }}</p>
                </div>
                <br><br><br>


              </td>
            </tr>

            <tr>
              <td colspan="6">
                <p>检查问题处理情况：{{ wentichuli }}</p>
                <br>
                <br>
                <div v-if="miaoshu != undefined">
                  整改描述：
                  {{ miaoshu }}
                </div>
                <br>

                相关图片：
                <br>
                <div v-if='img_list != []' style="padding-left: 60px;">
                  <img v-for="(item, index) in img_list" :src="item" :key='index' alt=""
                    style="width: 200px; height:200px;">
                  <div v-if="dczg_img_list.length != 0" style="float: right; padding-right: 300px;">
                    <div style="float: left;">
                      整改后：
                    </div>
                    <img v-for="(item, index) in dczg_img_list" :src="item" :key='index' alt=""
                      style="width: 200px; height:200px;">
                  </div>
                </div>

                <br>
                <br>
                <p style="padding-left:650px">检查负责人（签字）：</p>
              </td>
            </tr>
            <tr>
              <td> 参加检查人员（签字）</td>
              <td colspan="5">
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>

              </td>
            </tr>
          </table>
          <br>
          <!-- 下发通知单 -->
          <h1 v-if="wentichuli == '下发通知'">隐患整改通知单</h1>
          <br>
          <table v-if="wentichuli == '下发通知'" border="1" id='data2' style="border-collapse: collapse;">
            <tr>
              <td>
                受检单位
              </td>
              <td colspan="3" style="width: 680px;height: 70px;">
                {{ data_wenjian.pro_name }}
              </td>
            </tr>

            <tr>
              <td>
                检查类型
              </td>
              <td style="width: 240px;height: 70px;">
                {{ data_wenjian.work_type }}
              </td>
              <td>
                检查日期
              </td>
              <td style="width: 200px;height: 70px;">
                {{ data_wenjian.create_time }}
              </td>
            </tr>

            <tr>
              <td style="width: 200px;height: 50px;">
                检查负责人
              </td>
              <td>
                {{ data_wenjian.user_name }}
              </td>
              <td style="width: 200px;height: 50px;">
                参加检查人员
              </td>
              <td>
                {{ data_wenjian.woring.data3.data_xftz.xftz_qtjcry }}
              </td>
            </tr>
            <tr>
              <td style="width: 200px;height: 50px;">
                整改负责人
              </td>
              <td style="width: 200px;height: 50px;">
                {{ data_wenjian.woring.data3.data_xftz.xftz_zgfzr }}
              </td>
              <td style="width: 200px;height: 50px;">
                整改完成要求时间
              </td>
              <td style="width: 200px;height: 50px;">

                {{ data_wenjian.woring.data3.data_xftz.xftz_zgsx }}
              </td>
            </tr>
            <tr>
              <td style="width: 200px;height: 50px;">
                隐患类型
              </td>
              <td style="width: 200px;height: 50px;">

                {{ data_wenjian.woring.name }}

              </td>
              <td style="width: 200px;height: 50px;">
                通知书下发时间
              </td>
              <td style="width: 200px;height: 50px;">
                {{ data_wenjian.create_time }}
              </td>
            </tr>

            <tr>
              <td colspan="4" style="width: 200px;height: 300px;">
                <div v-if="miaoshu != undefined">
                  隐患标题：
                  {{ miaoshu }}
                  <br>
                  隐患内容：
                  <div v-if="count_on != 0">
                    <p v-for="(item) in list_data_on" :key='item.text' style="padding-left: 60px;">
                      {{ item.text }}</p>
                  </div>
                </div>
                <br>

                相关图片：
                <br>
                <!-- <div v-if='dczg_img_list!=[]' style="padding-left: 60px;"> -->
                <img :src="data_img" alt="" style="width: 200px; height:200px;">
                <!-- </div> -->
                <br>
              </td>
            </tr>

            <tr>
              <td colspan="4" style="width: 200px;height: 120px;">
                整改要求 ：
                <br><br>
                <div style="padding-left: 60px;">

                  {{ data_wenjian.woring.data3.data_xftz.xftz_zgyq }}
                </div>
              </td>
            </tr>

            <tr>
              <td colspan="2" style="width: 200px;height: 100px;">
                整改负责人（签字）：
              </td>
              <td colspan="2" style="width: 200px;height: 100px;">
                检查负责人（签字）：
              </td>
            </tr>

          </table>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
          </span>
        </el-dialog>
      </el-card>
    </el-card>
  </div>
</template>

<script>
import { addHiddenInfo1, addHiddenInfo13, get_work_data_examination_data, get_data_examination_data, deldata_examination_data } from "../../../request/hidden";
export default {
  name: "",
  props: [],
  data() {
    return {
      printObj1: {
        id: "printTests", //打印的ID，与打印内容的盒子id一致
        popTitle: "隐患排查记录", //打印的标题
      },
      multipleSelection: [],
      nowPage: 0,
      row_name: '',
      data_img: '',
      img_list: [],
      dczg_img_list: [],
      miaoshu: '',
      wentichuli: '',
      dialogVisible: false,
      tableData: [],
      tableData1: [],
      table2: [],
      digital: 1, //判断显示第几个卡片
      corporation: "25",
      corporation1: "",
      total: 1,
      total1: 1,
      page: 1,
      pagesize: 10,
      xid: 0,
      project: '',//查询项目input
      start_time: '',//日期开始
      end_time: '',
      id: '',//当前隐患排查记录查看详情当前项目的id
      type: '',//查询排查类型下拉框
      options: [{
        label: "低风险",
        value: "低风险"
      }, {
        label: "一般风险",
        value: "一般风险"
      }, {
        label: "较大风险",
        value: "较大风险"
      }, {
        label: "重大风险",
        value: "重大风险"
      }],
      data_wenjian: {},
      count_all: 0,
      count_on: 0,
      list_data: [],
      list_data_on: [],
      pjtid: '',

    };
  },
  watch: {
    $route() {
      this.pjtid = this.$route.query.id
    },
    pjtid() {
      this.digital = 1;
      this.getRisk();
    },
  },

  methods: {
    async del_shanchu(e) {
      var msg = {
        id: e
      }
      // const res = await addHiddenInfo1({'corporation':localStorage.getItem('corporations'),'page':this.page,'size':this.pagesize}); //两种方法 第一种
      const res = await deldata_examination_data(msg) //第二种
      console.log(res.data)
      this.idchaxun(this.id)
    },
    async chakan(e, row) {
      this.dialogVisible = true
      var msg = {
        id: e
      }
      this.row_name = row
      this.data_img = ''
      this.list_data = ''
      this.list_data_on = ''
      this.count_all = ''
      this.count_on = ''
      this.data_wenjian = ''
      this.wentichuli = ''
      this.miaoshu = ''
      this.img_list = []
      this.dczg_img_list = []
      // const res = await addHiddenInfo1({'corporation':localStorage.getItem('corporations'),'page':this.page,'size':this.pagesize}); //两种方法 第一种
      const res = await get_data_examination_data(msg) //第二种
      //  console.log(res,'图片数据???')
      this.data_img = res.data.data[0].zg_img
      this.list_data = res.data.data[0].all_lists
      this.list_data_on = res.data.data[0].on_lists
      this.count_all = res.data.data[0].all_lists.length
      this.count_on = res.data.data[0].on_lists.length
      this.data_wenjian = res.data.data[0]

      try {
        this.img_list = res.data.data[0].woring.img
        this.miaoshu = res.data.data[0].woring.textarea
        this.miaoshu = res.data.data[0].woring.data3.inpval
        this.wentichuli = res.data.data[0].woring.data3.name
        if (res.data.data[0].woring.data3.img3.length == 2) {
          this.img_list = []
          this.img_list.push(res.data.data[0].woring.data3.img3[1])
        } else {
          this.img_list = []
          this.img_list.push(res.data.data[0].woring.data3.img3[0])
        }
        // console.log(res.data.data[0].woring.data3.img3[1],'img3')
        // console.log(this.img_list,'img_list')
      } catch (e) {
        // 错误处理代码片段
        //console.log(e)
      }
      if (this.img_list == undefined) {
        this.img_list = res.data.data[0].woring.data3.data_dczg.dczg_xctp
      }

      try {
        if (res.data.data[0].woring.data3.data_dczg.dczg_xctp) {
          for (var i = 0; i < res.data.data[0].woring.data3.data_dczg.dczg_xctp.length; i++) {
            this.dczg_img_list.push(res.data.data[0].woring.data3.data_dczg.dczg_xctp[i])
          }
        }

        // console.log(this.dczg_img_list,'1111111111111111111111111111111111111111111111111111')
      } catch (e) {
        // 错误处理代码片段
      }

      //console.log(this.img_list)



    },
    //渲染页面
    async getRisk() {
      let msg = {
        name: this.project,
        unique_identification: localStorage.getItem("uid"),
        page: this.page,
        size: this.pagesize
      }
      if (localStorage.getItem('isdealer') == 'true') {
        if (localStorage.getItem('type') == 'pro') {
          msg.pro_id = localStorage.getItem('project')
        } else if (localStorage.getItem('type') == 'firm') {
          msg.firm_id = localStorage.getItem('project')
        }
      }
      // const res = await addHiddenInfo1({'corporation':localStorage.getItem('corporations'),'page':this.page,'size':this.pagesize}); //两种方法 第一种
      const res = await addHiddenInfo1(msg) //第二种
      this.total = res.data.count
      this.tableData = res.data.data;
    },

    //获取当前点击的一行用户详细信息
    //点击查看详情
    async idchaxun(id) {
      this.id = id;
      this.digital = 2;
      let msg = {
        pro_id: id,
      }
      const res = await get_work_data_examination_data(msg)
      console.log(res, 123)
      this.table2s = res.data.data
      if (this.table2s.length < 21) {
        this.table2 = this.table2s
      } else {
        this.table2 = this.table2s.slice(0, 20)
      }
    },
    load() {
      this.table2 = this.table2.concat(
        this.table2s.slice(this.nowPage * 20, (this.nowPage + 1) * 20)
      );
      this.nowPage++;
    },
    superior() {
      this.digital = 1;
      this.getRisk();
    },
    // 分页
    handleSizeChange(pageSize) {
      // console.log(`每页 ${pageSize} 条`);
      this.pagesize = pageSize;
      this.getRisk();
    },
    handleCurrentChange(page) {
      // console.log(`当前页: ${page}`);
      this.page = page;
      this.getRisk();
    },

    // 分页er
    handleSizeChange1(pageSize) {
      // console.log(`每页 ${pageSize} 条`);
      this.pagesize = pageSize;
      this.getRisk();
    },
    handleCurrentChange1(page) {
      // console.log(`当前页: ${page}`);
      this.page = page;
      this.getRisk();
    },
    //day1点击查询
    async query() {
      this.page = 1

      this.getRisk();
    },
    //重置
    reset() {
      this.project = '';
      this.getRisk();
    },
    //查询
    async query2() {
      this.idchaxun(this.id);
    },
    //重置
    reset2() {
      this.type = '';
      this.start_time = '';
      this.end_time = '';
      this.idchaxun(this.id)
    },

    // handleSelectionChange(val) {
    //   console.log(val);
    //   this.multipleSelection = val;
    // },
    // async exportExcel2() {
    //   let msg = {
    //     name: this.project,
    //     unique_identification: localStorage.getItem("uid"),
    //     page: 1,
    //     size: 10000000
    //   }
    //   if (localStorage.getItem('isdealer') == 'true') {
    //     if (localStorage.getItem('type') == 'pro') {
    //       msg.pro_id = localStorage.getItem('project')
    //     } else if (localStorage.getItem('type') == 'firm') {
    //       msg.firm_id = localStorage.getItem('project')
    //     }
    //   }
    //   const res = await addHiddenInfo1(msg) //第二种

    //   this.tableData1 = res.data.data;

    // },

  },
  mounted() {
    this.getRisk();
  },
  components: {},
  computed: {},
};
</script>

<style lang='less' scoped>
.el-form-item {
  margin-bottom: 10px;
}

.butt {
  margin-bottom: 10px;
}

.el-table {
  border-radius: 5px;
}
</style>
